import React, { useState } from "react";

import { PageLayout } from "./components/PageLayout";
import { loginRequest } from "./authConfig";
import { callMsGraph } from "./graph";
import { ProfileData } from "./components/ProfileData";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import "./App.css";
import Button from "react-bootstrap/Button";

/**
 * Renders information about the signed-in user or a button to retrieve data about the user
 */

const ProfileContent = () => {
  const { instance, accounts } = useMsal();
  const [graphData, setGraphData] = useState(null);

  function RequestProfileData() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        callMsGraph(response.accessToken).then((response) =>
          setGraphData(response)
        );
      });
  }
  console.log(accounts[0].username);
  return (
    <>
      <h5 className="profileContent">Welcome {accounts[0].username}</h5>
      {graphData ? (
        <ProfileData graphData={graphData} />
      ) : (
        // <Button variant="secondary" onClick={RequestProfileData}>
        //   Request Profile
        // </Button>
        <Button
          variant="secondary"
          href="https://securelogicsolutions.sharepoint.com/sites/SecurelogicExtranet"
          target="_blank"
        >
          Go to Securelogic Extranet
        </Button>
      )}
      <p />
      <h6>
        If you have trouble accessing the site or if it says Access Denied,
        please sign out and sign back in.
      </h6>
    </>
  );
};

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {
  return (
    <div className="App">
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <h5 className="card-title">
          Please sign-in to access the Securelogic Extranet.
        </h5>
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <PageLayout>
      <MainContent />
    </PageLayout>
  );
}
